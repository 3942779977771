//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    Header: () => import("@/components/Header"),
    CContent: () => import("@/components/CContent"),
    CTitle: () => import("@/components/CTitle"),
    CPages: () => import("@/components/CPages"),
    UserInfo: () => import("./userInfo"),
  },
  data: () => ({
    loading: false,
    selRow: null, //操作的用户
    searchVal: null, //搜索内容
    cellStyle: {
      textAlign: "center",
    },
    tableData: [],
    titleInfo: {
      btnShow: [
        { type: "addBtn", ishow: false, disabled: true },
        { type: "startBtn", ishow: false, disabled: true },
        { type: "closeBtn", ishow: false, disabled: true },
        { type: "delBtn", ishow: false, disabled: true },
      ],
      dropDown: {
        searchInput: {
          placeholder: "请输入用户名称",
        },
      },
    },
    pageData: {
      pageIndex: 1,
      pageSize: 10,
      totalNum: 0,
    },
  }),
  mounted() {
    this.getTableList();
  },
  methods: {
    /**
     * 获取团队列表
     */
    getTableList() {
      this.loading = true;
      const data = {
        seachtext: this.searchVal,
        pageIndex: this.pageData.pageIndex,
        pageSize: this.pageData.pageSize,
      };
      this.$http
        .get("/Management/UserManagement/UserList.ashx", { params: data })
        .then((resp) => {
          if (resp.res == 0) {
            this.tableData = resp.data.ListUsers;
            this.pageData.totalNum = resp.data.TotalCount;
          }
        })
        .finally(() => (this.loading = false));
    },
    /**
     * 搜索
     */
    searchClick(val) {
      this.pageData.pageIndex = 1;
      this.searchVal = val;
      this.getTableList();
    },
    /**
     * 分页
     */
    handlePaginationChange(a) {
      this.pageData = a;
      this.getTableList();
    },
    /**
     * 查看
     */
    handleView(a) {
      this.selRow = a;
      this.$modal.show("userInfo");
    },
    /**
     * 删除
     */
    handleDel(row, type) {
      let name = null;
      if (type == 1) {
        name = "启用";
      } else if (type == 0) {
        name = "禁用";
      } else {
        name = "删除";
      }
      this.$confirm(`此操作将${name}该用户, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .post("/Management/UserManagement/OperationUser.ashx", {
              usIds: [row.UsId],
              operation: type,
            })
            .then((resp) => {
              if (resp.res == 0) {
                this.$message({
                  type: "success",
                  message: `${name}成功!`,
                });
                this.getTableList();
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: `已取消${name}`,
          });
        });
    },
  },
};
